import {Button, Input, Card, Form} from 'antd';
import Link from 'next/link';
import IfComp from 'if-comp';
import Counter from './Counter';
import styles from './index.module.scss';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useRouter} from "next/router";
import {ApiGetWalletAddress, APILogin} from "@/api";
import {useState} from "react";
export default function Home() {

  const router = useRouter();
  const [username, setUsername] = useState("");

  const doLogin = () =>{
    APILogin({username:username}).then(resp=>{
      sessionStorage.setItem("token", resp);
      router.push("/home");
    });
  };

  return (
    <div className={styles.lg_box}>
      <Card title={"AIS Admin"} className={styles.lg_card}>
        <Form
          name={"normal_login"}
          className={"login-form"}
          initialValues={{ remember: true }}
          onFinish={()=>{
            doLogin();
          }}
        >
          <Form.Item
            name={"username"}
            rules={[{ required: true, message: '请输入谷歌验证码' }]}
          >
            <Input
              value={username}
              onChange={(v)=>{
                if (v.target.value.length > 6){
                  return;
                }
                setUsername(v.target.value);
              }}
              prefix={<UserOutlined className={"site-form-item-icon"} />}
              placeholder={"请输入谷歌验证码"}
            />
          </Form.Item>
          {/* <Form.Item */}
          {/*   name={"password"} */}
          {/*   rules={[{ required: true, message: '请输入密码' }]} */}
          {/* > */}
          {/*   <Input */}
          {/*     prefix={<LockOutlined className={"site-form-item-icon"} />} */}
          {/*     type={"password"} */}
          {/*     placeholder={"密码"} */}
          {/*   /> */}
          {/* </Form.Item> */}
          <Form.Item>
            <Button
              loading={false}
              type={"primary"}
              htmlType={"submit"}
              className={"login-form-button"}
              style={{
                width: "100%"
              }}
            >
             登录
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
