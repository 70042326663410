import host from "@/utils/host";

function obj2String(obj, arr = [], idx = 0) {
    for (let item in obj) {
        arr[idx++] = [item, obj[item]];
    }
    return new URLSearchParams(arr).toString();
}


/**
 * 真正的请求 服务端请求使用此request
 * @param url 请求地址
 * @param options 请求参数
 * @param method 请求方式
 * @param other 请求headers 如token
 */
 function commonFetch(url, options, method = 'GET', other) {
    let token = other.token;
    const searchStr = obj2String(options);
    let initObj = {};
    if (method === 'GET') { // 如果是GET请求，拼接url
      url += '?' + searchStr;
      initObj = {
        method: method,
        credentials: 'include',
        headers: new Headers({
            'token': token
        }),
      };
    } else {
      initObj = {
        method: method,
        credentials: 'include',
        dataType: 'json',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': token
        }),
        body: options
      };
    }
    return fetch(host + url, initObj).then((res) => {
      try {
        return  res.json();
      } catch {
        return {};
      }

    }).then((res) => {
      return res;
    });
  }

  const request = {

    GET(url, options, other) {
      return commonFetch(url, options, 'GET', other);
    },
    POST(url, options, other) {
      return commonFetch(url, options, 'POST', other);
    }
};

 export default request;
