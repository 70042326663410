/** *
 * 请求示例
 */
import request from "@/utils/request";  // 服务端请求
import ajax from "@/utils/ajaxRequest";  // 客户端请求

export const APILogin = (params) => {
  return ajax.getJson(`/wallet/verifyOTP?code=${params.username}`, {});
};

// 获取提现钱包
export const APIGetNowAddress = (params) => {
  let token = sessionStorage.getItem("token");
  return ajax.getJson(`/wallet/getAdminWallet?api_key=${token}`, {});
};

// 获取收款钱包
export const APIGetWithdrawAddress = (params) => {
  let token = sessionStorage.getItem("token");
  return ajax.getJson(`/wallet/getwithdrawToAddress?api_key=${token}`, {});
};

// 获取谷歌验证器二维码
export const APIGetGenerateOTPQrcodeUrl = (params) => {
  return ajax.getJson(`/wallet/generateOTPQrcodeUrl`, {});
};

// 设置管理员OTP
export const APISetAdminOtp = (params) => {
  let token = sessionStorage.getItem("token");
  return ajax.postJson(`/wallet/setAdminOTPSecret?api_key=${token}&secret=${params.secret}`, params);
};


// 设置管理员提现地址
export const APISetAdminWalletAddress = (params) => {
  let token = sessionStorage.getItem("token");
  return ajax.postJson(`/wallet/setAdminWallet?api_key=${token}&address=${params.address}&pk=${params.pk}`, JSON.stringify(params));
};

// 设置管理员收款地址
export const APISetAdminWithdrawWalletAddress = (params) => {
  let token = sessionStorage.getItem("token");
  return ajax.postJson(`/wallet/setwithdrawToAddress?api_key=${token}&address=${params.address}`, params);
};
